import React, { useEffect, useState } from "react";
import { FaSearch, FaPlus } from "react-icons/fa";
import { Link, Navigate, useParams } from "react-router-dom";
import ConfirmAlert from "../../commons/confirmAlert";
import Toast from "../../commons/Toastify";
import CategoryAlert from "./categoryAlert";
import { useNavigate } from "react-router-dom";

const PAGE_SIZE = 5; // Number of items per page

const ProductVendor = ({ user }) => {
    const { slug } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [filterStatus, setFilterStatus] = useState(slug ? slug : 'all');
    const [vendorID, setVendorID] = useState(user?._id || '');
    const [products, setProducts] = useState([]);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [toast, setToast] = useState({ show: false, type: '', title: '', message: '' });
    const navigate = useNavigate();

    const getProducts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_PRO}/getbyid/${vendorID}`);
            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }
            const data2 = await response.json();
            const data = data2.reverse();
            setProducts(data); // Update state with fetched data
        } catch (err) {
            console.error('Fetch error:', err);
        }
    };

    useEffect(() => {
        getProducts();
    }, [vendorID]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
        setCurrentPage(1); // Reset to the first page on search
    };

    const handleFilterChange = (status) => {
        setFilterStatus(status);
        setCurrentPage(1); // Reset to the first page on filter change
    };

    const filteredData = products.filter(item => {
        const matchesSearch = item.title.toLowerCase().includes(searchTerm) ||
            item.description.toLowerCase().includes(searchTerm) ||
            item.categoryID.name.toLowerCase().includes(searchTerm);
        const matchesFilter = filterStatus === 'all' ||
            (filterStatus === 'approved' && item.isApproved) ||
            (filterStatus === 'not-approved' && !item.isApproved);
        return matchesSearch && matchesFilter;
    });

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / PAGE_SIZE);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, totalItems);
    const currentData = filteredData.slice(startIndex, endIndex);

    const handleDeleteProduct = (id) => {
        setProductToDelete(id);
        setIsConfirmOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (productToDelete === null) return; // No product to delete

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_PRO}/deletebyid/${productToDelete}/${vendorID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setIsConfirmOpen(false);
            setProductToDelete(null);

            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }

            const SerRes = await response.json();

            setToast({
                show: true,
                type: 'success',
                title: 'Success',
                message: 'Product deleted successfully!',
            });

            // Show the success toast for 1 seconds, then navigate
            setTimeout(() => {
                setToast({ show: false });
            }, 1000); // Hide toast after 1 seconds

            getProducts();

        } catch (error) {
            console.error('Delete failed:', error);

            // Show the error toast
            setToast({
                show: true,
                type: 'error',
                title: 'Error',
                message: error.message || 'An error occurred. Please try again.',
            });

            setTimeout(() => {
                setToast({ show: false });
            }, 1000); // Hide toast after 1 seconds
        }
    };

    const handleCancelDelete = () => {
        setIsConfirmOpen(false);
        setProductToDelete(null);
    };

    const productView = (id) => {
        if (id) {
            navigate(`/vendor/products/details/${id}`);
        }
    };

    return (
        <div className="p-5 h-screen bg-gray-100 dark:bg-gray-900">
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-xl text-gray-800 dark:text-gray-100">Your Products</h1>
                <Link
                    onClick={() => setIsCategoryOpen(true)}>
                    <button className="flex items-center px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700">
                        <FaPlus className="mr-2" /> Add New Product
                    </button>
                </Link>
            </div>

            <div className="mb-4 relative">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="w-full p-2 pl-10 shadow-md bg-white dark:bg-boxdark dark:text-bodydark dark:shadow-none rounded-lg focus:outline-none"
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400" />
            </div>

            <div className="flex justify-end mb-4">
                <button
                    onClick={() => handleFilterChange('all')}
                    className={`px-4 py-2 mx-2 text-sm font-medium rounded-lg ${filterStatus === 'all' ? 'text-blue-500 hover:underline dark:text-blue-500' : 'bg-gray-400'}`}
                >
                    All Products
                </button>
                <button
                    onClick={() => handleFilterChange('approved')}
                    className={`px-4 py-2 mx-2 text-sm font-medium rounded-lg ${filterStatus === 'approved' ? 'text-blue-500 hover:underline dark:dark:text-blue-500' : 'bg-gray-400'}`}
                >
                    Approved Products
                </button>
                <button
                    onClick={() => handleFilterChange('not-approved')}
                    className={`px-4 py-2 mx-2 text-sm font-medium rounded-lg ${filterStatus === 'not-approved' ? 'text-blue-500 hover:underline dark:dark:text-blue-500' : 'bg-gray-400'}`}
                >
                    Not Approved Products
                </button>
            </div>

            {/* Table view */}
            <div className="overflow-auto rounded-lg shadow-lg hidden md:block bg-white dark:bg-boxdark dark:text-bodydark dark:shadow-none">
                <table className="w-full">
                    <thead className="bg-gray-50 border-b-2 border-gray-200 dark:bg-gray-700 dark:border-gray-600">
                        <tr>
                            <th className="w-16 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">S.No</th>
                            <th className="p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Title</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Image</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Price</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Quantity</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Category</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Approved</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100 dark:divide-gray-700">
                        {currentData.map((item, index) => (
                            <tr key={item._id}
                                className="hover:bg-gray dark:hover:bg-body cursor-pointer"
                            >
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap"
                                    onClick={productView.bind(this, item._id)}>
                                    {startIndex + index + 1}.
                                </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300"
                                    onClick={productView.bind(this, item._id)}
                                >
                                    {item.title}
                                </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap"
                                onClick={productView.bind(this, item._id)}
                                >
                                    {item.images ? (
                                        <img src={item.images} alt={item.title} className="w-16 h-10 object-cover rounded" />
                                    ) : (
                                        <span className="text-gray-500">No Image</span>
                                    )}
                                </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap"
                                onClick={productView.bind(this, item._id)}
                                >
                                    ₹{item.price}
                                </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap"
                                onClick={productView.bind(this, item._id)}
                                >
                                    {item.quantity}
                                    </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap"
                                onClick={productView.bind(this, item._id)}
                                >
                                    {item.categoryID.name}
                                    </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap"
                                onClick={productView.bind(this, item._id)}
                                >
                                    <span className={`p-1.5 text-xs font-medium uppercase tracking-wider ${item.isApproved ? 'text-green-800 bg-green-200 dark:text-green-200 dark:bg-green-600' : 'text-red-800 bg-red-200 dark:text-red-200 dark:bg-red-600'} rounded-lg`}>
                                        {item.isApproved ? 'Yes' : 'No'}
                                    </span>
                                </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">
                                    <Link to={`/add-new-products/${item._id}`} className="text-blue-500 hover:underline dark:text-blue-400"
                                    >
                                        Edit
                                    </Link> |
                                    <a href="#" className="text-red-500 hover:underline dark:text-red-400"
                                        onClick={() => handleDeleteProduct(item._id)}>
                                        Delete
                                    </a> |
                                    <Link to={`/vendor/products/details/${item._id}`} className="text-success hover:underline dark:text-success"
                                    >
                                        View
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Mobile view */}
            <div className="block md:hidden">
                {currentData.map((item, index) => (
                    <div key={item._id} className="bg-white dark:bg-boxdark rounded-lg shadow-md mb-4 p-4 dark:bg-gray-800 dark:text-gray-200">
                        <div className="mb-2"
                        onClick={productView.bind(this, item._id)}
                        >
                            {item.images ? (
                                <img src={item.images} alt={item.title} className="w-full h-full object-cover rounded" />
                            ) : (
                                <div className="w-full h-32 bg-gray-200 rounded flex items-center justify-center text-gray-500">No Image</div>
                            )}
                        </div>
                        <div className="flex items-center justify-between mb-2"
                        onClick={productView.bind(this, item._id)}
                        >
                            <h2 className="text-lg font-semibold">{item.title}</h2>
                        </div>
                        <p className="text-sm text-gray-700 dark:text-gray-300 mb-2"
                        onClick={productView.bind(this, item._id)}
                        >
                            Price: ₹{item.price}
                            </p>
                        <p className="text-sm text-gray-700 dark:text-gray-300 mb-2"
                        onClick={productView.bind(this, item._id)}
                        >
                            Quantity: {item.quantity}
                            </p>
                        <p className="text-sm text-gray-700 dark:text-gray-300 mb-2"
                        onClick={productView.bind(this, item._id)}
                        >
                            Category: {item.categoryID && item.categoryID.name ? item.categoryID.name : 'N/A'}
                            </p>
                        <span className={`p-1.5 text-xs font-medium uppercase tracking-wider  ${item.isApproved ? 'text-green-800 bg-green-200 dark:text-green-200 dark:bg-green-600' : 'text-red-800 bg-red-200 dark:text-red-200 dark:bg-red-600'} rounded-lg`}
                        onClick={productView.bind(this, item._id)}
                        >
                            {item.isApproved ? 'Approved' : 'Not-Approved'}
                        </span>
                        <div className="flex justify-end">
                            <Link to={`/add-new-products/${item._id}`} className="text-blue-500 hover:underline dark:text-blue-400"
                            >
                                Edit
                            </Link> |
                            <a href="#" className="text-red-500 hover:underline dark:text-red-400"
                                onClick={() => handleDeleteProduct(item._id)}>
                                Delete
                            </a> |
                            <Link to={`/vendor/products/details/${item._id}`} className="text-success hover:underline dark:text-success"
                            >
                                View
                            </Link>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pagination */}
            <div className="flex justify-center mt-4">
                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                        key={i}
                        onClick={() => handlePageChange(i + 1)}
                        className={`px-3 py-1 mx-1 text-sm font-medium rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 dark:bg-gray-700 dark:text-gray-300'}`}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>

            {/* Category Alert */}
            {isCategoryOpen && (
                <CategoryAlert
                    user={user}
                    onCancel={() => setIsCategoryOpen(false)}
                />
            )}

            {/* Confirm Alert */}
            {isConfirmOpen && (
                <ConfirmAlert
                    message="Are you sure you want to delete this product?"
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
            )}

            {/* Toast notification */}
            {toast.show && (
                <div className="fixed top-20 left-1/2 transform -translate-x-1/2 z-50">
                    <Toast type={toast.type} title={toast.title} message={toast.message} onClose={() => setToast({ show: false })} />
                </div>
            )}
        </div>
    );
};

export default ProductVendor;
