import React, { useEffect, useState } from "react";
import { BiSolidPhoneCall } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Toast from "../../commons/Toastify";
import VendorEnquiry from "../home/vendorEnquiry";

const ProductDetails = ({ user }) => {
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [viewPhone, setViewPhone] = useState(null);
    const [isVendorEnquiryOpen, setIsVendorEnquiryOpen] = useState(false);
    const [vendorID, setVendorID] = useState('');
    const [categoryID, setCategoryID] = useState('');

    const [toast, setToast] = useState({ show: false, type: '', title: '', message: '' });
    const navigate = useNavigate();

    const id = localStorage.getItem('productId');

    useEffect(() => {
        const fetchProduct = async () => {
            setLoading(true);
            setError(null); // Reset error state before fetching

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_PRO}/getpid/${id}`);

                if (response.ok) {
                    const data = await response.json();
                    setProduct(data);
                } else {
                    // Handle HTTP errors
                    const errorData = await response.json();
                    if (response.status === 404) {
                        setError('Product not found');
                    } else if (response.status === 500) {
                        setError('Server error: Please try again later');
                    } else {
                        setError(errorData.message || 'An error occurred');
                    }
                }
            } catch (error) {
                // Handle network errors or other exceptions
                setError('Network error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchProduct();
        } else {
            setError('No product ID provided');
            setLoading(false);
        }
    }, [id]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    if (!product) {
        return <p>No product data available.</p>;
    }


    // Function to get the token from local storage
    const getToken = () => localStorage.getItem('token');

    const sendEnquiry = (vendorID, categoryID) => {
        const token = getToken();

        if (!token) {
            // Show the success toast
            setToast({
                show: true,
                type: 'info',
                title: 'info',
                message: 'Please Login First!',
            });

            // Show the success toast for 1 seconds, then navigate
            setTimeout(() => {
                navigate('/login');
            }, 1000);
            return;
        }

        setIsVendorEnquiryOpen(true);
        setVendorID(vendorID);
        setCategoryID(categoryID);
    };


    const viewNumber = (productId) => {
        const token = getToken();

        if (!token) {
            // Show the success toast
            setToast({
                show: true,
                type: 'info',
                title: 'info',
                message: 'Please Login First!',
            });

            // Show the success toast for 1 seconds, then navigate
            setTimeout(() => {
                setToast({ show: false });
            }, 1000);
            return;
        }
        setViewPhone(prevId => prevId === productId ? null : productId);
    };

    return (
        <>
            <div className="bg-gray-100 mb-5 bg-white shadow-lg mt-5 dark:bg-boxdark">
                <div className="container mx-auto px-4 py-8">
                    <div className="flex flex-wrap -mx-4">
                        {/* Product Images */}
                        <div className="w-full md:w-1/2 px-4 mb-8">
                            <img
                                src={product && product.images}
                                alt="Product"
                                className="w-full h-auto rounded-lg shadow-md mb-4"
                                id="mainImage"
                            />
                            <div className="flex gap-4 py-4 justify-center overflow-x-auto">
                                <img
                                    src={product && product.images}
                                    alt="Thumbnail 1"
                                    className="size-16 sm:size-20 object-cover rounded-md cursor-pointer opacity-60 hover:opacity-100 transition duration-300"
                                    onclick="changeImage(this.src)"
                                />
                                <img
                                    src={product && product.images}
                                    alt="Thumbnail 2"
                                    className="size-16 sm:size-20 object-cover rounded-md cursor-pointer opacity-60 hover:opacity-100 transition duration-300"
                                    onclick="changeImage(this.src)"
                                />
                                <img
                                    src={product && product.images}
                                    alt="Thumbnail 3"
                                    className="size-16 sm:size-20 object-cover rounded-md cursor-pointer opacity-60 hover:opacity-100 transition duration-300"
                                    onclick="changeImage(this.src)"
                                />
                                <img
                                    src={product && product.images}
                                    alt="Thumbnail 4"
                                    className="size-16 sm:size-20 object-cover rounded-md cursor-pointer opacity-60 hover:opacity-100 transition duration-300"
                                    onclick="changeImage(this.src)"
                                />
                            </div>
                        </div>
                        {/* Product Details */}
                        <div className="w-full md:w-1/2 px-4">
                            <h2 className="text-xl font-bold mb-2">{product && product.title}</h2>
                            <div className="mb-4">
                                <span className="text-2xl font-bold mr-2">₹{product && product.price}</span>
                                <span className="text-gray-500 line-through text-danger">{product && product.cutprice ? `₹${product.cutprice}` : ''}</span>
                            </div>
                            <div className="flex items-center mb-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-yellow-500"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-yellow-500"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-yellow-500"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-yellow-500"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-yellow-500"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="ml-2 text-gray-600">4.5 (120 reviews)</span>
                            </div>
                            <p className="text-gray-700 mb-6">
                                {product && product.description}
                            </p>
                            <div className="flex space-x-4 mb-6">
                                <button
                                    className="bg-green-600 flex gap-2 items-center text-white px-6 py-2 font-bold rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                    onClick={() => sendEnquiry(product && product.vendorID._id, product &&  product.categoryID._id)}
                                    >
                                    Get Best Price
                                </button>
                                <button
                                    className="flex items-center font-bold lg:px-8 px-5 py-1 rounded text-amber-500 hover:text-blue-500"
                                    onClick={() => viewNumber(product && product._id)}
                                    >
                                    <BiSolidPhoneCall className="mr-2" />
                                    <span>
                                    {viewPhone === product._id ? product.vendorID.phone : "View Number"}
                                    </span>
                                </button>
                            </div>
                            <div className="p-4">
                                <h3 className="text-xl font-semibold mb-4 text-gray-800">Specifications:</h3>
                                {product && product.specifications.map((item, i) => (
                                    <ul key={item._id} className="list-disc list-inside space-y-2 text-gray-600">
                                        <li className="flex justify-between items-center border-b border-gray-200 py-2">
                                            <span className="font-medium">{item.title}</span>
                                            <span className="text-gray-900">{item.desc}</span>
                                        </li>
                                    </ul>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="border-t-2 border-gray-200 mt-8 pt-4">
                    <h1 className="text-2xl font-bold mb-6 text-gray-800 text-center text-success">{product && product.brandID.name}</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="p-4 text-left">
                            <h2 className="text-xl font-semibold mb-2 text-gray-700">About</h2>
                            <p className="text-gray-600">
                                We are a leading provider of cutting-edge technology solutions in AgriInput,
                                delivering unparalleled innovation and customer service. Our mission is to
                                empower businesses worldwide in the agriculture sector.
                            </p>
                            <p className="mt-5">{product && product.vendorID.email}</p>
                            <p>+91 {product && product.vendorID.phone}</p>
                            <p>GST : {product && product.vendorID.gstNumber}</p>
                        </div>
                        <div className="p-4 md:text-right">
                            <h2 className="text-xl font-semibold mb-2 text-gray-700">Address</h2>
                            <p className="text-gray-600">
                                {product && product.nearby}, {product && product.city}<br />
                                {product && product.state},<br />
                                {product && product.country}, ({product && product.postal_code})
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Vendor Enquiry */}
            {isVendorEnquiryOpen && (
                <VendorEnquiry
                    onCancel={() => setIsVendorEnquiryOpen(false)}
                    onConfirm={() => setIsVendorEnquiryOpen(false)}
                    vendorID={vendorID}
                    categoryID={categoryID}
                    buyerID={user._id}
                />
            )}


            {/* Toast notification */}
            {toast.show && (
                <div className="fixed top-20 left-1/2 transform -translate-x-1/2 z-50">
                    <Toast type={toast.type} title={toast.title} message={toast.message} onClose={() => setToast({ show: false })} />
                </div>
            )}
        </>
    )
};
export default ProductDetails;