import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ProductDetails = () => {
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedcategory, setSelectedcategory] = useState('');


    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_PRO}/getpid/${id}`);
                if (response.ok) {
                    const data = await response.json();
                    setItem(data);
                    setSelectedcategory(data.categoryID.name);
                    console.log('data** :', data);
                } else {
                    const errorData = await response.json();
                    setError(errorData.message || 'Error fetching product');
                }
            } catch (error) {
                setError('Fetch error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        if (id) fetchProduct();
    }, [id]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!item) return <p>No product found</p>;

    return (
        <div className="bg-gray-50 text-gray-800">
            <div className="container mx-auto p-6">
                <div className="grid md:grid-cols-2 gap-6">
                    {/* Product Images */}
                    <div className="space-y-4">
                        <img
                            src={item.images || 'default-image-url'} // Handle missing images
                            alt="Product Image"
                            className="w-full h-80 object-cover rounded-lg shadow-lg"
                        />
                        <div className="flex space-x-4">
                            {/* Dynamically generate thumbnails if available */}
                            {item.images ? (
                                <img
                                    src={item.images}
                                    alt={`pics`}
                                    className="w-1/4 h-20 object-cover rounded-lg border border-gray-200 cursor-pointer"
                                />
                            ) : (
                                <p>No thumbnails available</p>
                            )}
                        </div>
                    </div>

                    {/* Product Details */}
                    <div className="space-y-4">
                        <h1 className="text-3xl font-bold text-blue-800"><span className='text-xl text-gray-600'>Title: </span>{item.title || 'N/A'}</h1>
                        <p className="text-lg text-gray-700"><span className='text-xl text-gray-600'>Description: </span>{item.description || 'N/A'}</p>

                        <div className="flex items-center space-x-4">
                            <span className="text-xl font-semibold text-green-600">₹{item.price} &nbsp;
                                {item.cutprice ? (
                                    <span className=' line-through text-slate-400 text-[15px]'>₹{item.cutprice}</span>
                                ) : ''}
                            </span>
                            {/* <span className="text-sm text-green-500">In Stock: {item.quantity || 'N/A'}</span> */}
                            <span className="text-sm text-green-500">
                                {item.quantity !== 'undefined' && !isNaN(Number(item.quantity))
                                    ? (Number(item.quantity) === 0
                                        ? `Out of Stock`
                                        : `In Stock ${item.quantity}`)
                                    : `${item.quantity}`}
                            </span>
                        </div>

                        <div className="bg-white p-4 rounded-lg shadow-lg dark:bg-boxdark dark:text-bodydark">
                            <h2 className="text-xl font-semibold text-gray-800">Specifications</h2>
                            <ul className="list-disc pl-5 space-y-2 text-gray-700">
                                {item.specifications && item.specifications.length > 0 ? item.specifications.map((spec, index) => (
                                    <li key={index}>
                                        <strong className="text-blue-600">{spec.title}:</strong> {spec.desc}
                                    </li>
                                )) : (
                                    <li>No specifications available</li>
                                )}
                            </ul>
                        </div>

                        <div className="bg-white p-4 rounded-lg shadow-lg mt-4 dark:bg-boxdark dark:text-bodydark">
                            <h2 className="text-xl font-semibold text-gray-800">
                                {
                                    selectedcategory === "AgriServices"
                                        ? 'Service Area'
                                        : 'Warranty'
                                }: <span className='text-sm text-orange-400'>{item.warranty || 'N/A'}</span>
                            </h2>
                        </div>

                        <div className="flex items-center space-x-4 mt-4 bg-white p-4 rounded-lg shadow-lg dark:bg-boxdark dark:text-bodydark">
                            <h4 className='text-xl font-semibold'>Product Approved:</h4>
                            <span className={`p-1.5 text-xs font-medium uppercase tracking-wider ${item.isApproved ? 'text-green-800 bg-green-200 dark:text-green-200 dark:bg-green-600' : 'text-red-800 bg-red-200 dark:text-red-200 dark:bg-red-600'} rounded-lg`}>
                                {item.isApproved ? "Yes" : "No"}
                            </span>
                        </div>

                        <div className="bg-white p-4 rounded-lg shadow-lg mt-4 dark:bg-boxdark dark:text-bodydark">
                            <h2 className="text-xl font-semibold text-gray-800">Vendor Details</h2>
                            <p><strong className="text-blue-600">Name:</strong> {item.vendorID?.username || 'N/A'}</p>
                            <p><strong className="text-blue-600">Email:</strong> {item.vendorID?.email || 'N/A'}</p>
                            <p><strong className="text-blue-600">Phone:</strong> {item.vendorID?.phone || 'N/A'}</p>
                            <p><strong className="text-blue-600">GST Number:</strong> {item.vendorID?.gstNumber || 'N/A'}</p>
                        </div>
                    </div>
                </div>

                {/* Address Information */}
                <div className="bg-white p-4 rounded-lg shadow-lg mt-6 dark:bg-boxdark dark:text-bodydark">
                    <h2 className="text-xl font-semibold text-gray-800">Location</h2>
                    <p><strong className="text-blue-600">Country:</strong> {item.country || 'N/A'}</p>
                    <p><strong className="text-blue-600">State:</strong> {item.state || 'N/A'}</p>
                    <p><strong className="text-blue-600">City:</strong> {item.city || 'N/A'}</p>
                    <p><strong className="text-blue-600">Nearby:</strong> {item.nearby || 'N/A'}</p>
                    <p><strong className="text-blue-600">Postal Code:</strong> {item.postal_code || 'N/A'}</p>
                </div>
            </div>
        </div>
    );
};

export default ProductDetails;
